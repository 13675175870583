import React from "react";
import * as S from "./HeroStyles";

const Hero = () => (
  <S.HeroWrapper>
    <div className="intro">
      <h1>
        Bringing Bold <br />
        Decisions <br />
        To Life
      </h1>
      <h2>
        Prepared <br /> & <br /> Scheduling for <br /> the 2025 Season
      </h2>
      <a href="/new-quote">
        <button>Let's Talk</button>
      </a>
    </div>
  </S.HeroWrapper>
);

export default Hero;

import React, { useState } from "react";
import { QuoteWrap } from "./QuoteStyles";
import { useSearchParams } from "react-router-dom";
import InputMask from "react-input-mask";
import colorLogo from "../../assets/images/colorLogo.png";
import { PDFDocument } from "pdf-lib";
import pdfTemplate from "../../assets/pdfTemplate.pdf";
import Swal from "sweetalert2/dist/sweetalert2.all.js";
import emailjs from "emailjs-com";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";

const services = [
  {
    id: 0,
    name: "Spring Clean Up",
    description: `Grading & Cleaning Beds. Installing Mulch (Black Triple Shred). Hand Spade Edging Garden Beds & Collecting Waste. Applying Commerical Herbicide Preventer & Killer`,
  },
  {
    id: 1,
    name: "Shrub & Plant Work",
    description: `Shrub Trimming: Small Plant Maintenance`,
  },
  {
    id: 2,
    name: "Landscape Design",
    description: "Installation of Design Provided",
  },
  {
    id: 3,
    name: "Hardscape",
  },
  {
    id: 4,
    name: "Fall Clean Up",
    description: "Leaf Removal & Maintenance of Perennials & Small Plants",
  },
  {
    id: 5,
    name: "Other",
  },
];

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const taxRates = {
  Clermont: ".0675",
  Hamilton: ".0780",
  Butler: ".0650",
  Warren: ".0675",
  Brown: ".0725",
  Other: ".0675",
};

let defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  street: "",
  city: "",
  state: "OH",
  zip: "",
  county: "",
  selectedServices: [],
};

function ContactInformation(props) {
  const { formData, handleFormChange } = props;
  return (
    <div className="contact">
      <div className="flex-row">
        <div className="input-label-group">
          <label>
            First Name:
            <input
              type="text"
              placeholder="First Name..."
              value={formData.firstName}
              onChange={(e) => handleFormChange("firstName", e.target.value)}
              required
            />
          </label>
        </div>
        <div className="input-label-group">
          <label>
            Last Name:
            <input
              type="text"
              placeholder="Last Name..."
              value={formData.lastName}
              onChange={(e) => handleFormChange("lastName", e.target.value)}
            />
          </label>
        </div>
      </div>
      <div className="input-label-group">
        <label>
          Email:
          <input
            type="email"
            placeholder="E-Mail..."
            value={formData.email}
            onChange={(e) => handleFormChange("email", e.target.value)}
            required
          />
        </label>
      </div>
      <div className="input-label-group">
        <label>
          Phone:
          <InputMask
            mask="(999) 999-9999"
            value={formData.phone}
            onChange={(e) => handleFormChange("phone", e.target.value)}
          >
            {(inputProps) => (
              <input {...inputProps} type="text" placeholder="(123) 456-7890" />
            )}
          </InputMask>
        </label>
      </div>
      <div className="input-label-group">
        <label>
          Street:
          <input
            type="text"
            placeholder="Street Address..."
            value={formData.street}
            onChange={(e) => handleFormChange("street", e.target.value)}
            required
          />
        </label>
      </div>
      <div className="flex-row">
        <div className="input-label-group">
          <label>
            City:
            <input
              type="text"
              placeholder="City..."
              value={formData.city}
              onChange={(e) => handleFormChange("city", e.target.value)}
              required
            />
          </label>
        </div>
        <div className="input-label-group" style={{ width: "30px" }}>
          <label>
            State:
            <select
              value={formData.state}
              onChange={(e) => handleFormChange("state", e.target.value)}
              required
            >
              {states.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="input-label-group">
          <label>
            Zip Code:
            <input
              type="number"
              placeholder="Zip Code..."
              value={formData.zip}
              onChange={(e) => handleFormChange("zip", e.target.value)}
              required
            />
          </label>
        </div>
      </div>
      <div className="input-label-group">
        <label>
          County:
          <select
            value={formData.county}
            onChange={(e) => handleFormChange("county", e.target.value)}
            required
          >
            <option hidden>Select County...</option>
            {Object.keys(taxRates).map((county) => {
              return (
                <option key={county} value={county}>
                  {county}
                </option>
              );
            })}
          </select>
        </label>
      </div>
    </div>
  );
}

function Services(props) {
  const { formData, onAddRemoveService } = props;
  return (
    <div className="services">
      <h1>Services</h1>
      <div>
        {services.map((service) => {
          return (
            <div key={service.id} className="select-service">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => onAddRemoveService(service)}
                  checked={formData.selectedServices.some(
                    (x) => x.id === service.id
                  )}
                />
                {service.name}
              </label>
              {service.description && (
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-${service.id}`}>
                      {service.description}
                    </Tooltip>
                  }
                >
                  <span role="button" tabIndex="0" className="text-info ms-1">
                    <FaInfoCircle size={18} color="green" />
                  </span>
                </OverlayTrigger>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function Quote() {
  const [formData, setFormData] = useState(defaultFormData);
  const [loader, setLoader] = useState(false);
  const [params] = useSearchParams();
  const encodedName = params.get("id");

  let name;

  if (encodedName) {
    try {
      name = atob(encodedName);
    } catch (err) {
      console.log("Invalid Base64", err);
    }
  }

  const handleFormChange = (prop, newValue) => {
    setFormData((prev) => ({
      ...prev,
      [prop]: newValue,
    }));
  };

  const handleAddRemoveService = (service) => {
    setFormData((prev) => {
      if (prev.selectedServices.some((x) => x.id === service.id)) {
        return {
          ...prev,
          selectedServices: prev.selectedServices.filter(
            (x) => x.id !== service.id
          ),
        };
      } else {
        return {
          ...prev,
          selectedServices: [...prev.selectedServices, service],
        };
      }
    });
  };

  const getTaxRate = (county) => {
    try {
      if (county) {
        return taxRates[county];
      } else {
        return ".0675";
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generatePdfEmail = async (e) => {
    e.preventDefault();
    setLoader(true);

    const formPdf = await fetch(pdfTemplate).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdf);

    const form = pdfDoc.getForm();

    const {
      firstName,
      lastName,
      email,
      phone,
      street,
      city,
      state,
      zip,
      county,
      selectedServices,
    } = formData;

    form.getTextField("Name").setText(`${firstName} ${lastName}`);

    form.getTextField("Address1").setText(street);
    form.getTextField("Address2").setText(`${city}, ${state} ${zip}`);

    form.getTextField("Phone Number").setText(phone);
    form.getTextField("Email").setText(email);

    selectedServices.forEach((service, index) => {
      form.getTextField(`ServiceProductRow${index + 1}`).setText(service.name);
      form
        .getTextField(`DescriptionRow${index + 1}`)
        .setText(service.description);
    });

    let taxRate = getTaxRate(county);

    form.getTextField("Tax Rate").setText(taxRate);

    const pdfBytes = await pdfDoc.save();

    const base64String = btoa(
      new Uint8Array(pdfBytes).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );

    const attachment = `data:application/pdf;base64,${base64String}`;

    const templateParams = {
      from_name: `${firstName} ${lastName}`,
      email: email,
      content: attachment,
    };

    try {
      await emailjs.send(
        "service_g5o1icl",
        "template_dch1ixb",
        templateParams,
        "user_AtqFI3nvTWlplzNyaEPlg"
      );

      await Swal.fire({
        title: "Thank you!",
        position: "top",
        icon: "success",
        showConfirmButton: true,
        timer: 2000,
      });

      setFormData(defaultFormData);
      setLoader(false);
      window.location.href = "/";
    } catch (err) {
      console.error(err);
      setLoader(false);
    }

    // const blob = new Blob([pdfBytes], { type: "application/pdf" });
    // const blobUrl = URL.createObjectURL(blob);

    // const link = document.createElement("a");
    // link.href = blobUrl;
    // link.download = "CompletedForm.pdf";
    // document.body.appendChild(link);
    // link.click();

    // // Clean up
    // document.body.removeChild(link);
    // URL.revokeObjectURL(blobUrl);
  };

  return (
    <QuoteWrap>
      <div>
        <a href="/">
          <img src={colorLogo} />
        </a>
      </div>
      <div className="welcome">
        <h1>Welcome{name ? `, ${name}!` : "!"}</h1>
        <p>Please fill out the below information.</p>
      </div>
      <form onSubmit={generatePdfEmail}>
        <ContactInformation
          formData={formData}
          handleFormChange={handleFormChange}
        />
        <Services
          formData={formData}
          onAddRemoveService={handleAddRemoveService}
        />
        <button type="submit" disabled={loader}>
          Submit
        </button>
      </form>
    </QuoteWrap>
  );
}

import React from "react";
import { ServicesWrapper, ServicesContent } from "./ServicesStyles";

const Services = () => (
  <ServicesWrapper>
    <h1 id="services">Services We Offer</h1>
    <ServicesContent>
      <div>
        <h4>
          <b>Custom Landscape Design and Installation</b>
        </h4>
        <p>
          Collaborate directly with Alex, our passionate owner & designer, to
          bring your dream landscape to life. Utilizing cutting-edge CAD
          software, we ensure you understand and love your design before
          installation. Our meticulous process includes a comprehensive watering
          guide for long-term success.
        </p>
      </div>
      <div>
        <h4>
          <b>Weed Treatment Solutions</b>
        </h4>
        <p>
          Rest easy knowing our licensed team employs a 3-stage approach,
          combining manual weed removal, targeted herbicide treatment, and
          potent preventatives to keep your ornamental beds immaculate.
        </p>
      </div>
      <div>
        <h4>
          <b>Tree Services</b>
        </h4>
        <p>
          From lower branch removal to handling trees up to 2000lbs, our skilled
          landscapers ensure your trees maintain their natural beauty and
          structural integrity.
        </p>
      </div>
      <div>
        <h4>
          <b>Boulder Walls</b>
        </h4>
        <p>
          Opt for stability and natural beauty with our meticulously installed
          boulder walls, perfect for challenging terrain or a rustic aesthetic.
        </p>
      </div>
      <div>
        <h4>
          <b>Rock Installation</b>
        </h4>
        <p>
          Avoid disappointment with our expert rock installation techniques,
          tailored to your unique landscape for lasting beauty and
          functionality.
        </p>
      </div>
      <div>
        <h4>
          <b>Hand Spade Edging</b>
        </h4>
        <p>
          Perfect the borders of your garden beds with our meticulous hand spade
          edging, using purpose-built tools for pristine results that enhance
          the contrast between lawn and garden.
        </p>
      </div>
      <div>
        <h4>
          <b>Bed Maintenance & Premium Mulching</b>
        </h4>
        <p>
          Say goodbye to uneven garden beds! We meticulously prepare your beds
          and offer top-quality mulch options including Triple Dyed Black,
          Triple Dyed Brown, and Triple Natural Brown for a pristine finish.
        </p>
      </div>
      <div>
        <h4>
          <b>Shrub & Plant Care</b>
        </h4>
        <p>
          Trust our experienced team to provide tailored maintenance, whether
          it's delicate hand pruning or precise sheering, ensuring your plants
          thrive and beautify your landscape for years to come.
        </p>
      </div>
      <div>
        <h4>
          <b>Retaining Walls & Paver Patios</b>
        </h4>
        <p>
          Elevate your outdoor space with meticulously crafted retaining walls
          and paver patios. We prioritize hidden details for longevity and
          structural integrity, setting your outdoor area apart.
        </p>
      </div>
      <div>
        <h4>
          <b>Landscape Lighting</b>
        </h4>
        <p>
          Enhance the ambiance and security of your property with expertly
          installed landscape lighting, ensuring your outdoor space shines even
          after the sun sets.
        </p>
      </div>
      <div>
        <h4>
          <b>Gutter Downspout Drainage</b>
        </h4>
        <p>
          Ensure proper drainage and preserve the integrity of your landscape
          with our professional installation services, designed for both
          functionality and aesthetic appeal.
        </p>
      </div>
      <div>
        <h4>
          <b>Irrigation Installation</b>
        </h4>
        <p>
          Installation of irrigation is an extremely important process that
          ensures your landscape thrives. We check every box to ensure the
          system is installed properly using quality parts & service to ensure
          the success of water conservation & proper operation.
        </p>
      </div>
    </ServicesContent>
    <div style={{ marginTop: "15px" }} className="hours">
      <h4>Hours</h4>
      <p>Monday-Friday 7am-5pm</p>
      <p>Saturday-Sunday 8am-3pm</p>
    </div>
  </ServicesWrapper>
);

export default Services;

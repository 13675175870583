import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/header/header";
import Hero from "./components/Hero/Hero";
import Menu from "./components/Menu/Menu";
import About from "./components/About/About";
import ContactMe from "./components/ContactMe.js/ContactMe";
import Services from "./components/Services/Services";
import GalleryPage from "./components/Gallery/GalleryPage";
import Footer from "./components/Footer/Footer";
import Quote from "./components/Quote/Quote";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function Home() {
  return (
    <>
      <Hero />
      <About />
      <Services />
      {/* <ContactMe /> */}
    </>
  );
}

function MainLayout() {
  const location = useLocation();

  // Apply mobile meta color conditionally based on page (dirty)
  useEffect(() => {
    const themeMetaTag = document.querySelector('meta[name="theme-color"]');
    const previousColor = themeMetaTag?.getAttribute("content");

    if (location.pathname === "/new-quote") {
      themeMetaTag?.setAttribute("content", "#e6e6e6");
    } else {
      themeMetaTag?.setAttribute("content", "#433735");
    }

    return () => {
      if (themeMetaTag) {
        themeMetaTag.setAttribute("content", previousColor || "#433735");
      }
    };
  }, [location]);
  const [openMenu, setOpenMenu] = useState(false);

  let isQuotePage = location.pathname === "/new-quote";

  return (
    <>
      {!isQuotePage && (
        <>
          <Menu openMenu={openMenu} setOpenMenu={setOpenMenu} />
          <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/new-quote" element={<Quote />} />
      </Routes>
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <MainLayout />
    </BrowserRouter>
  );
}

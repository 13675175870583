import styled from "styled-components";

export const QuoteWrap = styled.div`
  background: #e6e6e6;
  font-family: "brother-1816", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  img {
    width: 25em;
    margin-top: 1em;
    @media (max-width: 385px) {
      width: 20em;
    }
  }

  button {
    margin: 0 2em;
  }

  .welcome {
    padding: 2em 2em 1em 2em;
  }

  .services {
    padding: 2em 2em 1em 2em;
  }

  .select-service {
    margin: 0.5em;
    input {
      margin-right: 5px;
    }
  }

  .flex-row {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
  }
  .contact {
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    gap: 1em;
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  .input-label-group {
    flex-grow: 1;
    label {
      display: block;
      width: 100%;
    }
    input,
    select {
      display: block;
      width: 100%;
    }
  }
`;
